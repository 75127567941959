<template lang="pug">
b-overlay(
	:show="payLoadingStore.loading && payStore.isInitialized"
	variant="white"
	spinner-variant="primary")
	template(#overlay)
		.d-flex.flex-column.justify-content-center.align-items-center
			b-spinner(variant="primary")
			p.mb-0.mt-3(v-if="payStore.loadingText") {{ payStore.loadingText }}
				span.loading-dots
					span .
					span .
					span .
	div(v-if="!stripeV2Store.isStripePaymentElementsCompatible")
		.px-3.py-4
			.card-rows-wrapper.card-rows-wrapper--small-padding
				.card-rows-row
					div
						span(v-if="showServiceFee")
							small.text-muted {{ $t('components.app.pay.index.serviceFee.title') }}
							i(class="fe fe-help-circle ml-1 text-muted" id="service-fee-info" @click="serviceFeeInfoModalOpen = true")
						h4.mb-0 {{ $t('components.app.pay.index.amount.title') }}
						small.text-muted {{ $t('components.app.pay.index.amount.includesVat') }}
					div
						div(v-if="showServiceFee" style="text-align: right;")
							h5.m-0.text-muted(v-if="payStore.isInitialized") {{ formatPrice(payPriceStore.priceData.platformFee) }}
						hr.mt-1.mb-2(v-if="showServiceFee")
						div.d-flex.align-items-center
							b-badge(
								v-if="payPriceStore.discountPercentage"
								size="sm"
								variant="success"
								style="line-height: normal") -{{ payPriceStore.discountPercentage }}%
							h4.m-0.ml-2(v-if="payStore.isInitialized")
								span(v-if="payPriceStore.isUsingDiscounts" style="text-decoration: line-through") {{ payPriceStore.priceData.amountWithoutCoupon }}
								span &nbsp;{{ formatPrice(payPriceStore.priceData.amountToPay) }}
							b-skeleton(v-else width="70px" height="19px")

				.card-rows-row(v-if="payCouponStore.canUseCoupon")
					div
						h4.mb-0 {{ $t('components.app.pay.index.discountCode') }}
					div.small
						PayCoupon

				.card-rows-row(v-if="paySubscriptionProductGiftCardStore.showSubscriptonProductGiftCardOption")
					div
						h5.mb-0 {{ $t('components.app.pay.index.subscriptionProductGiftCard') }}
					div
						SubscriptionProductGiftCard

				.card-rows-row
					div
						// h5.mb-0 {{ $t('components.app.pay.index.paymentMethod.title') }}
						.pay-icons(class="flex gap-1 max-w-[130px] flex-wrap")
							span(v-html="svgIcons.applePay('black')")
							span(v-html="svgIcons.googlePay('black')")
							span(v-html="svgIcons.mobilePay('black')")
							span(v-html="svgIcons.payPal('black')")
							span(v-html="svgIcons.card('black')")

					div.d-flex.align-items-center
						span(v-if="!payPaymentMethodStore.allowedPaymentTypesData.length && !payLoadingStore.loading")
							b-button(
								disabled
								size="sm"
								variant="outline-warning") {{ $t('components.app.pay.index.noPaymentOptions.title') }}

						span.text-muted.mr-3(v-else-if="payPaymentMethodStore.selectedPaymentTypeData")
							span#selected-payment-method-element {{ $t(payPaymentMethodStore.selectedPaymentTypeData.i18nKey) }}

						b-skeleton(v-else width="70px")
						b-button#change-payment-method-button(
							v-if="payPaymentMethodStore.allowedPaymentTypesData.length > 1"
							size="sm"
							:disabled="payStore.paymentIsStarted"
							variant="outline-primary"
							@click="payPaymentMethodStore.showSelectPaymentType = true") {{ $t('components.app.pay.index.paymentMethod.change') }}

				.card-rows-row(v-if="payRestPointsStore.canDoRestPointPayment")
					div
						h5.mb-0 {{ $t('components.app.pay.useRestPointsInPayment.text', [payRestPointsStore.useRestPointsData.pointsAmount]) }}
					div
						b-form-checkbox(
							size="md"
							switch
							:disabled="payStore.paymentIsStarted"
							v-model='useRestPoints')

				.card-rows-row(v-if="payUserProductSubscriptionPaymentStore.canDoUserSubscriptionPayment")
					div
						h5.mb-0 {{ $t('components.app.pay.index.canDoUserSubscriptionPayment', [payUserProductSubscriptionPaymentStore.useUserProductSubscriptionData.subscriptionProductName]) }}
					// div
						b-form-checkbox(
							size="md"
							switch
							:disabled="payStore.paymentIsStarted"
							v-model='useUserProductSubscriptionPayment')

				.card-rows-row(v-if="payPaymentMethodStore.selectedPaymentType === 'PayPal'")
					b-row.pt-2
						b-col.col-auto.d-flex.align-items-center
							i.fe.fe-alert-circle.payment-refund-warning
						b-col.p-0.pt-0.mt-1
							small.text-muted {{ $t('components.app.pay.index.refundWarning') }}
				
				.card-rows-row(v-else-if="payPaymentMethodStore.selectedPaymentType === 'MobilePayInvoice' && browser === safari")
					b-row.pt-2
						b-col.col-auto.d-flex.align-items-center
							i.fe.fe-alert-circle.payment-refund-warning
						b-col.p-0.pt-0.mt-1
							small.text-muted {{ $t('components.app.pay.index.safariMobilePayWarning') }}

				.card-rows-row(v-if="payPointsStore.removeDiscountToDoPayment && payPriceStore.showRemoveDiscount")
					div
						small.text-muted {{ $t('components.app.pay.index.showRemoveDiscountBox.text') }}
					div
						b-button(
							@click="payPriceStore.removeDiscounts()"
							size="sm"
							variant="outline-danger") {{ $t('components.app.pay.index.showRemoveDiscountBox.removeDiscount') }}

				.card-rows-row(v-if="payPaymentMethodStore.selectedPaymentType === 'Points' && (payPointsStore.availablePointAccounts.length > 1 || payPointsStore.availablePointAccountsIncludesOtherThanPrivate)")
					div
						h5.mb-0 {{ $t('components.app.pay.index.pointAccount') }}
					div
						b-form-select(
							v-model="payPointsStore.selectedPointAccountId"
							:disabled="payStore.paymentIsStarted"
							:options="payPointsStore.availablePointAccountsForSelect"
							size="sm")


		.px-3.py-3.border-top
			b-card.card-small-padding.card-bg-inverted.mt-3.mb-0(v-if="!payPaymentMethodStore.allowedPaymentTypesData.length && !payLoadingStore.loading")
				p.mb-2 {{ $t('components.app.pay.index.noPaymentOptions.title') }}
				small.text-muted {{ $t('components.app.pay.index.noPaymentOptions.text') }}


			div(v-else-if="payPaymentMethodStore.selectedPaymentType")
				div(v-if="payPaymentMethodStore.selectedPaymentType === 'StripeCard' && payStripeStore.useStripeWeb === true")
					StripeWebCard

				div(v-else-if="payPaymentMethodStore.selectedPaymentType === 'PayPal'")
					b-skeleton(v-if="!payPayPalStore.payPalButtonIsInitialized || payStore.paymentIsStarted" width="100%" height="45px")
					div(:id="payPalButtonId" :style="{ 'display': payPayPalStore.payPalButtonIsInitialized && !payStore.paymentIsStarted ? 'block' : 'none' }")

				b-button.pay-button-p(
					block
					class="!flex items-center justify-center"
					size="lg"
					v-else
					variant="primary"
					:disabled="payStore.paymentIsStarted || !payStore.isInitialized"
					@click="payStore.startPayment()")
					span {{ $t('components.app.pay.index.payWith') }}
					span(
						class="inline-block"
						v-if="payPaymentMethodStore.selectedPaymentTypeData.icon"
						v-html="payPaymentMethodStore.selectedPaymentTypeData.icon()")
					span(v-else) &nbsp;{{ $t(payPaymentMethodStore.selectedPaymentTypeData.i18nKey) }}
					span.ml-3(v-if="payStore.paymentIsStarted")
						b-spinner.button-loading(small type="grow")

			b-skeleton(v-else width="100%" height="47px")

	//- This is shown for Stripe Payment Elements compatible versions of the app
	div(v-else)
		.px-3.py-4
			.card-rows-wrapper.card-rows-wrapper--small-padding
				//- Amount Section
				.card-rows-row(v-if="payStore.purchaseData?.productType !== 'SubscriptionProduct'")
					div
						span(v-if="showServiceFee")
							small.text-muted {{ $t('components.app.pay.index.serviceFee.title') }}
							i(class="fe fe-help-circle ml-1 text-muted" id="service-fee-info" @click="serviceFeeInfoModalOpen = true")
						h4.mb-0 {{ $t('components.app.pay.index.totalAmount') }}
						small.text-muted {{ $t('components.app.pay.index.amount.includesVat') }}
					div
						div(v-if="showServiceFee" style="text-align: right;")
							h5.m-0.text-muted(v-if="payStore.isInitialized") {{ formatPrice(payPriceStore.priceData.platformFee) }}
						hr.mt-1.mb-2(v-if="showServiceFee")
						div.d-flex.align-items-center
							b-badge(
								v-if="payPriceStore.discountPercentage"
								size="sm"
								variant="success"
								style="line-height: normal") -{{ payPriceStore.discountPercentage }}%
							h4.m-0.ml-2(v-if="payStore.isInitialized")
								span(v-if="payPriceStore.isUsingDiscounts" style="text-decoration: line-through") {{ payPriceStore.priceData.amountWithoutCoupon }}
								span &nbsp;{{ formatPrice(payPriceStore.priceData.amountToPay ) }}
							b-skeleton(v-else width="70px" height="19px")
				
				//- Discount Code Section
				.card-rows-row(v-if="payCouponStore.canUseCoupon")
					div
						h4.mb-0 {{ $t('components.app.pay.index.discountCode') }}
					div.small
						PayCoupon
				
				//- Subscription Product Gift Card Section - CAMPAIGN CODE
				.card-rows-row(v-if="paySubscriptionProductGiftCardStore.showSubscriptonProductGiftCardOption")
					div
						h5.mb-0 {{ $t('components.app.pay.index.subscriptionProductGiftCard') }}
					div
						SubscriptionProductGiftCard
				
				//- Use Points Section
				.card-rows-row(v-if="paymentMethodStoreV2.isPointsAllowedPaymentMethod && payPointsStoreV2.selectedPointAccount")
					PayWithPoints
			
		PayButton
			
	b-modal(
		:title="$t('components.app.pay.index.paymentTypeModal.title')"
		title-tag="h4"
		centered
		v-model="payPaymentMethodStore.showSelectPaymentType"
		hide-footer
		size="sm")

		.cards-container
			b-card.card-small-padding.cursor-pointer.hover-scale.shadow-none.bg-light.card-no-border(
				@click="payPaymentMethodStore.setPaymentType(paymentType.paymentType, 'selectPaymentType')"
				v-for="(paymentType, index) in payPaymentMethodStore.allowedPaymentTypesData" :key="index")
				.x(class="flex items-center justify-between")
					p.m-0 {{ $t(paymentType.i18nKey) }}
					span.pay-icon-list(
						v-if="paymentType.icon"
						v-html="paymentType.icon('black')")
	
	ServiceFeeInfoModal(:open="serviceFeeInfoModalOpen" @close="serviceFeeInfoModalOpen = false")

</template>

<script lang="ts">
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayPaymentMethodStore } from '@/store/modules/pay/paymentMethod'
import { Component, Vue } from 'vue-property-decorator'

import PayCoupon from './coupon.vue'
import SubscriptionProductGiftCard from './subscriptionProductGiftCard.vue'
import StripeWebCard from './stripeWebCard.vue'
import { usePayLoadingStore } from '@/store/modules/pay/loading'
import { usePayCouponStore } from '@/store/modules/pay/coupon'
import { usePayRestPointsStore } from '@/store/modules/pay/restPoints'
import { usePayUserProductSubscriptionPaymentStore } from '@/store/modules/pay/userProductSubscriptionPayment'
import { svgIcons } from '@/utils/svgIcons'
import { usePayPointsStore } from '@/store/modules/pay/points'
import { usePayPriceStore } from '@/store/modules/pay/price'
import { payPalButtonId, usePayPayPalStore } from '@/store/modules/pay/payPal'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import { usePaySubscriptionProductGiftCardStore } from '@/store/modules/pay/subscriptionProductGiftCard'
import { getCurrentBrowser, Browser } from '@/utils/getCurrentBrowser'
import { usePayPaymentTokenStore } from '@/store/modules/pay/paymentToken'
import { usePayV2PaymentMethodStore } from '@/store/modules/payV2/paymentMethodV2'
import { usePayV2PointsStore } from '@/store/modules/payV2/pointsV2'
import PayWithPoints from './payWithPoints.vue'
import PayButton from './payButton.vue'
import { usePayV2Store } from '@/store/modules/payV2/payV2'
import { usePayV2StripeStore } from '@/store/modules/payV2/stripeV2'
import { currencyFormatter } from '@/utils/currencyFormatter';
import { useLocationStore } from '@/store/modules/location'
import ServiceFeeInfoModal from './serviceFeeInfoModal.vue'

@Component({
	components: {
		PayCoupon,
		StripeWebCard,
		SubscriptionProductGiftCard,
		PayWithPoints,
		PayButton,
		ServiceFeeInfoModal
	},
	apollo: {

	},
	data() {
		return {
			safari: Browser.Safari
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	// V2 stores
	stripeV2Store = usePayV2StripeStore()
	paymentMethodStoreV2 = usePayV2PaymentMethodStore()
	payPointsStoreV2 = usePayV2PointsStore()

	// V1 stores
	payStore = this.stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store() : usePayStore()
	payPriceStore = usePayPriceStore()
	payLoadingStore = usePayLoadingStore()
	payPaymentMethodStore = usePayPaymentMethodStore()
	payCouponStore = usePayCouponStore()
	payRestPointsStore = usePayRestPointsStore()
	payPointsStore = usePayPointsStore()
	payPayPalStore = usePayPayPalStore()
	payStripeStore = usePayStripeStore()
	paySubscriptionProductGiftCardStore = usePaySubscriptionProductGiftCardStore()
	payUserProductSubscriptionPaymentStore = usePayUserProductSubscriptionPaymentStore()
	locationStore = useLocationStore()
	paymentTokenStore = usePayPaymentTokenStore()

	svgIcons = svgIcons
	payPalButtonId = payPalButtonId
	browser: Browser | null = null

	serviceFeeInfoModalOpen: boolean = false

	created() {
		this.browser = getCurrentBrowser(window.navigator.userAgent)
		window.addEventListener('beforeunload', async function (event) {
			event.preventDefault()
			const payStore = usePayStore()
			const payV2Store = usePayV2Store()
			
			payStore.browserUnload()
			payV2Store.browserUnload()
		})
	}

	formatPrice(value: number) {
		if (this.locationStore.userLocation?.currency) {
			return currencyFormatter(
				value,
				this.locationStore.userLocation?.currency
			);
		} else {
			return value;
		}
	}

	get showServiceFee() {
		if (!this.payPriceStore.priceData?.platformFee) {
			return false
		}

		const selectedPaymentType = this.stripeV2Store.isStripePaymentElementsCompatible ? 
			this.paymentMethodStoreV2.selectedPaymentType : 
			this.payPaymentMethodStore.selectedPaymentType

		return (selectedPaymentType !== 'Points' && selectedPaymentType !== 'Free' && selectedPaymentType !== 'PayPal')
	}

	//* Rest points
	get useRestPoints() {
		//@ts-ignore
		return this.payRestPointsStore.useRestPoints
	}
	set useRestPoints(newValue: boolean) {
		this.payRestPointsStore.setUseRestPoints(newValue, 'shouldDoRestPointPaymentLocalChange')
	}
}
</script>

<style lang="sass">

.pay-button-p
	svg
		width: auto
		height: 18px
		margin-left: 10px

.pay-icons
	// display: flex
	opacity: 0.8
	svg
		margin-right: 6px
		height: 12px
		width: auto


.pay-icon-list
	svg
		width: auto
		height: 25px

.payment-refund-warning
	color: #FF9D97

</style>
