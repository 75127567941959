import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "bookli-277415.firebaseapp.com",
  projectId: "bookli-277415",
  storageBucket: "bookli-277415.appspot.com",
  messagingSenderId: "498206518563",
  appId: "1:498206518563:web:be4daf0d8e58955111f096",
  measurementId: "G-8NW263242X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logAnalyticsEvent = (eventName: string, eventParams?: Record<string, any>) => {
    logEvent(analytics, eventName, eventParams);
};
