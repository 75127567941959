<template lang="pug">

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SetUserFcmToken, DeleteUserFcmToken } from '@/graphql'
import { Capacitor } from '@capacitor/core'
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications'
import { Preferences } from '@capacitor/preferences'
import { UserState, useUserStore } from '@/store/modules/user'
import { logAnalyticsEvent } from '@/utils/fcmAnalytics'

@Component({
  components: {

  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  currentToken: null | string = null
  User = useUserStore()

  async created() {
    const check = Capacitor.isPluginAvailable('PushNotifications')

    if (!check) return

    const storageToken = await Preferences.get({ key: 'FCMToken' })
    if (storageToken.value) this.currentToken = storageToken.value

    PushNotifications.addListener('registration', async (token: Token) => {
      await Preferences.set({ key: 'FCMToken', value: token.value })
      this.currentToken = token.value
      this.addFCMKeyToUser(token.value)
    })

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error(`Error on registration ${JSON.stringify(error)}`)
    })
    
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log(`Push received: ${JSON.stringify(notification)}`)

      const notificationId = notification.id;
      const notificationType = notification.data?.notification_type;

      await this.logNotificationAnalytics('notification_received', notificationId, notificationType);
    })
    
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {

      console.log(`Push action performed: ${JSON.stringify(notification)}`)

      const notificationId = notification.notification.id;
      const notificationType = notification.notification.data?.notification_type;

      await this.logNotificationAnalytics('notification_opened', notificationId, notificationType);

      // TODO: Review if we still want this
      // if (notification.notification.data.type) {
      //   const type = notification.notification.data.type

      //   if (type === 'ResourceReminderBeforeBooking')
      //     this.$router.push({ name: 'User.ResourceBooking', params: { id: notification.notification.data.resourceBookingId } })

      // }
    })
  }

  async logNotificationAnalytics(nameEvent: string, notificationId: string, notificationType: string) {
    try {
      // Log to Firebase Analytics
      logAnalyticsEvent(nameEvent, {
        notification_id: notificationId,
        notification_type: notificationType,
      });

      console.log(`Notification ${notificationId} of type ${notificationType} logged to Firebase.`);
    } catch (error) {
      console.error('Failed to log notification to Firebase analytics:', error);
    }
  }


  @Watch('User.currentUser', { })
  userChange(now: UserState['user'] | null, prev: UserState['user'] | null) {
    if (now) {
      if (this.currentToken) this.addFCMKeyToUser(this.currentToken)
    }
    else if (prev) {
      this.removeFCMKeyFromUser(prev.id)
    }
  }

  async addFCMKeyToUser(token: string) {
    await this.$apollo.mutate({
      mutation: SetUserFcmToken,
      variables: {
        fcmToken: token
      }
    })
  }

  async removeFCMKeyFromUser(userId: string) {
    await this.$apollo.mutate({
      mutation: DeleteUserFcmToken,
      variables: {
        userId: userId
      }
    })
  }
}
</script>

<style lang="sass" scoped>

</style>