export default {
    firstNameLabel: "First name",
    firstNamePlaceHolder: "Enter first name",
    lastNameLabel: "Last name",
    lastNamePlaceHolder: "Enter last name",
    countryLabel: "Country",
    phoneLabel: "Phone number",
    phonePlaceHolder: "Enter phone number",
    deletePhoneNumber: "Delete phone number",
    emailLabel: "Email",
    emailPlaceHolder: "Enter email",
    updateButton: "Update user info",
    updateButtonLoading: "Updating...",
    phoneNumberError: "Phone number is not valid."
}