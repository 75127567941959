export function availableCountryPhoneNumbers(): string []  {
    return [
        'LU', 'PW', 'TW', 'UY', 'AE', 'AR', 'MX', 'JM', 'AL', 'BE',
        'AD', 'RS', 'VE', 'ZZ', 'CU', 'PF', 'DE', 'NL', 'NZ', 'TR',
        'IN', 'BW', 'FM', 'HN', 'ZA', 'FK', 'HU', 'GI', 'IS', 'BN', 'MH',
        'BA', 'CH', 'SM', 'NO', 'MT', 'AT', 'HK', 'SK', 'CZ', 'FR',
        'FI', 'IE', 'FO', 'SE', 'EE', 'JP', 'IT', 'RO', 'DK', 'MO', 'GR',
        'NA', 'BH', 'CL', 'LT', 'SG', 'ES', 'LI', 'LV', 'GB', 'AU', 'CR',
        'PT', 'BR', 'CA', 'US', 'PL', 'CN', 'MK', 'KR', 'CO', 'TH', 'CY', 'GL',
    ]
}