export default {
  title: 'Payment',

  noPaymentOptions: {
    title: 'No payment options',
    text: 'This company does not have the ability to receive payments on this product. Please contact support.'
  },

  loadingText: {
    one: 'Creating payment - do not leave the page!',
    two: 'Buying product',
    three: 'Completing payment'
  },

  amount: {
    title: 'Amount',
    includesVat: 'Includes VAT'
  },
  
  totalAmount: 'Total amount',
  serviceFee: {
    title: 'Service fee',
  },

  subscriptionProductGiftCard: 'Campaign code',

  discountCode: 'Discount code',
  paymentMethod: {
    title: 'Payment method',
    change: 'Change'
  },

  restPoints: 'Rest points',
  removeDiscount: {
    title: 'Remove discount',
    remove: 'Remove'
  },
  pointAccount: 'Point account',
  privatePointAccount: 'Private',
  points: 'points',
  canDoUserSubscriptionPayment: 'Your are using your subscription to get a discount ({0})',

  payWith: 'Pay with',

  paymentTypeModal: {
    title: 'Choose payment method'
  },

  paymentOptions: {
    creditCard: 'Credit card',
    points: 'Points',
    free: 'Free',
    subscription: 'Subscription (free)',
    payPal: 'PayPal'
  },
  showRemoveDiscountBox: {
    text: 'You have points in your account, but they can not be used with discounts, as it seems they have come via a points package',
    removeDiscount: 'Remove discounts'
  },

  refundWarning: 'Please note that with the selected payment option; in the case of any refunds we are only able to refund your purchase as points to your user account.',
  safariMobilePayWarning: 'Please note that MobilePay payments require popups to be allowed in your browser settings for optimal functionality. Alternatively, for the best user experience on mobile devices, we recommend using the LeDap app.',
  proceedToPayment: 'Proceed to Payment',
  usePoints: 'Use points'
}