export default {
  title: 'Create an event',
  description: 'Fill in the fields below and create an event your users can sign up for.',

  type: {
    label: 'Type',
    placeholder: 'Choose a type',
    firstOption: 'Choose a type...'
  },

  price: {
    label: 'Price for participation',
    placeholder: 'Price for participation'
  },

  allowUserProductSubscriptionPayment: 'Allow payment with subscription?',
  allowUserProductSubscriptionPaymentIsOfAType: 'Discount applies only to peak memberships (memberships with only one discount, no matter A/B-time)',
  lastRegistrationDateShouldBeFirstDay: 'The last registration date must be at the start of the event',

  lastRegistrationDate: {
    label: 'Last registration date',
    placeholder: 'Last registration date'
  },

  maxRegistrations: {
    label: 'Max registrations'
  },

  notifyUsersOnBooking: 'Send message to the following users when booking addition / cancellation',
  requireUserBookingNote: {
    checkbox: 'User must answer a question to be able to sign up',
    description: 'Note that the user must fill in something in the input field when this function is switched on.',
    questionLabel: "Question (eg: 'What is your level?' or 'Write the name of your partner')",
    questionPlaceholder: 'Write a question'
  },
  privateWorkshopKey: {
    labelCheckbox: 'This is a private event',
    labelKey: 'Password that the user must enter in order to access the event',
    placeholder: 'Write a password',
    description: 'Note that the user must fill in a password, in order to book this event, when this function is switched on.'
  },
  date: {
    sameWorkshopMultipleDaysTitle: 'Event extends over several days',
    oneDayTitle: 'Event consists of one day',
    manyDatesTitle: 'Create multiple independent dates',

    addCourtBooking: 'Add court booking',
    selectCourts: 'Choose below which courts you want to book in the period',

    addDate: 'Add day',
    selectedCourts: 'Courts',

    addWorkshopDayDate: {
      title: 'Add day to this event'
    },

    manyDates: {
      emptyTable: 'Create dates and they will be listed here',
      createDates: {
        title: 'Create dates',
      },
      updateDate: {
        title: 'Update date',
        onlyShowingAvailableCourts: 'Is only showing available courts',
      },
      invalid: {
        title: 'No Dates Added',
        description: "You haven't added any dates. Please add some dates in order to proceed."
      }
    },
    sameWorkshopMultipleDays: {
      invalid: {
        title: 'No Days Added',
        description: "You haven't added any days for the workshop. Please add some days in order to proceed."
      }
    }
  },

  getWorkshopResourcesError: {
    missingLocation: 'Missing locations',
    missingDate: 'Missing date'
  },

  createWorkshopButton: 'Create event',
  createWorkshopLoadingButton: 'Creating event...',
  
  workshopCreated: {
    title: 'What do you want to do now?',
    text: 'Your event has been created. What will you do now?',
    isManyDates: 'You have created many dates and they will all be created in next minutes',
    actions: {
      copy: "Copy the current info and create a new event",
      new: 'Create a new event',
      home: 'Go to event overview'
    }
  },
  invalidForm: {
    title: 'Invalid Form',
    description: 'Form is invalid. Please fill in all the required fields.'
  }
}