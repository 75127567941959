export default {
  
  week: 'Week',
  weekItem: 'Week',

  type: 'Type',

  location: 'Location',

  readMoreButton: 'Read more',

  noWorkshops: {
    title: 'No events this week',
    description: 'Try to choose another week.'
  },

  search: {
    searchInputTitle: 'Search for an event...',
    noResults: {
      title: 'No events found with the given name.',
      description: 'Try to search for another name or choose a different week.'
    }
  }
}