<template>
    <div v-if="open">
        <b-modal
            v-model="bvInternalOpen"
            :title="$t('components.app.pay.serviceFeeInfoModal.title')"
            title-tag="b"
            centered
            hide-footer
            size="md"
        >
            <div>
                {{ $t('components.app.pay.serviceFeeInfoModal.description') }}
            </div>
        </b-modal>
    </div>
</template>
<script lang="ts">
import Vue, { computed } from 'vue'

export default Vue.extend({
    props: {
        open: {
            type: Boolean,
            required: true,
            default: false,
        }
    },
    setup(props, { emit }) {
        const bvInternalOpen = computed({
            get: () => props.open,
            set: (val) => {
                if (!val) {
                    close()
                }
            }
        })

        const close = (): void => {
            emit('close')
        }

        return {
            bvInternalOpen
        }
    }
})
</script>